<template>
  <div class="my-second qian bgcolor">
      <div class="qian-main">
          <Head :title="$t('mine.qiandao')" :show="true" :bg="true"/>
          <div class="qian-main-top">
              <div class="qian-main-top-left">
                  {{$t('mine.lian_qian')}} <p v-for="(l,i) in nums" :key="i">{{l}}</p> {{$t('common.day')}}
              </div>
              <img class="qian-main-top-right" src="../../../assets/images/pai.png" alt="">
          </div>

          <div class="qian-main-box">
              <div class="qian-main-box-one">
                  <div class="qian-one">
                      {{$t('qian.sign_gift')}}:<span>{{obj.total_pv}}</span>
                  </div>
                  <p class="qian-two">{{$t('qian.sign_week_get')}}</p>
                  <!-- <van-button class="qian-month">2020年11月</van-button> -->
                  <div class="rilibox">
                      <Calendar ref="rili"  :getQingDaoData="getQingDaoData" :qianDaoinToday="qianDaoinToday" v-if="obj.list"   :list = "obj.list"  ></Calendar>
                      <div class="status-list">
                          <div>
                              <p class="p1"></p><span>{{$t('qian.sign_ready')}}</span>
                          </div>
                          <div>
                              <p class="p2"></p><span>{{$t('qian.sign_not')}}</span>
                          </div>
                          <div>
                              <p class="p3"></p><span>{{$t('qian.sign_ready_today')}}</span>
                          </div>
                          <div>
                              <p class="p4"></p><span>{{$t('qian.sign_no_today')}}</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="qian-main-bottom">
                    <h3>{{$t('qian.sign_rules')}}:</h3>
                    <p>{{$t('qian.sign_rules_one')}}</p>
                    <p>{{$t('qian.sign_rules_two')}}</p>
                </div>
          </div>
      </div>

        <!-- 签到成功 -->
        <van-overlay class="g-overlay" :show="show" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="wrapper-box">
                    <div class="wrapper-main">
                        <p class="wrapper-top">
                            {{$t('qian.sign_success')}}
                        </p>
                        <img class="img-liwu" :src="require('@/assets/images/liwu2.png')" alt="">
                        <p class="txt-more">{{$t('qian.sign_add_pv')}}</p>
                        <p class="txt-lian txt-tong">{{$t('qian.sign_continue')}} <span class="color-main">1</span> {{$t('qian.day')}}</p>
                        <p class="txt-end txt-tong">{{$t('qian.sign_continue_more')}}</p>
                        <img @click="show = false" class="wrapper-btn" :src="require('@/assets/images/closeicon.png')" alt="">
                    </div>
                </div>
                
            </div>
        </van-overlay>
  </div>
</template>


<script>
export default {
    data(){
        return {
            show:false,
            obj:{},
            nums:[]
        }
    },
    methods:{
        qianDaoinToday(){
            this.$ajax.calendarSignup()
            .then(res=>{
                if(res.code==200){
                    this.show = true;
                    this.getQingDaoData()
                }
            })
        },
        getQingDaoData(){
            this.$ajax.calendarList()
            .then(res=>{
                if(res.code==200){
                    this.obj = {...res.data};
                    this.obj.maxtime =  this.obj.maxtime ?  this.obj.maxtime :0;
                    var a = this.obj.maxtime<10 ? '0'+this.obj.maxtime : this.obj.maxtime.toString()
                    this.nums = a.split(''); 
                    var currentMonth = new Date().getMonth() + 1;
                    this.$nextTick(()=>{
                        this.$refs.rili.initData(this.$refs.rili.month);
                        this.$forceUpdate();
                    })
                }
            }) 
        }
    },

    mounted(){
        this.getQingDaoData();   
    }
}
</script>
